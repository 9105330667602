<template>
  <div>
    <div class="record_title padding-sm">
        <img class="record_back" src="img/backb.png" alt="" @click="goback">
        <span>PK详情</span>
    </div>
    <div class="record_detail padding-lr-sm padding-tb">
        <div class="display_flex justify-content_flex-justify align-items_center">
            <div class="display_flex flex-direction_column align-items_center" :class="(pkDetail.pk_status == 4 || pkDetail.pk_status == 5) ? 'record_two':'record_one'" :style="{backgroundImage: 'url(img/'+((pkDetail.pk_status == 4 || pkDetail.pk_status == 5) ? 'one1':'one')+'.png)'}">
                <img class="record_head" :src="oneUser.pic" alt="" @click="checkUser(oneUser.id)">
                <div class="display_flex align-items_center margin-top-sm">
                    <div class="record_name">{{oneUser.nickname}}</div>
                    <div class="cha-level margin-left-xs" :style="{backgroundImage: 'url(' + 'img/level/level0/'+oneUser.level+'.png' + ')'}"></div>
                </div>
                <div class="record_id margin-top-xs">ID:{{oneUser.u_id}}</div>
                <div class="record_charm margin-top-xs">魅力值：{{oneUser.count_charm}}</div>
                <img v-if="pkDetail.pk_status == 1 || pkDetail.pk_status == 2" class="record_status" src="img/success.png" alt="">
                <img v-if="pkDetail.pk_status == 3" class="record_status" src="img/ping.png" alt="">
            </div>
            <div class="display_flex flex-direction_column align-items_center" :class="(pkDetail.pk_status == 4 || pkDetail.pk_status ==5) ? 'record_two':'record_one'" :style="{backgroundImage: 'url(img/'+((pkDetail.pk_status == 4 || pkDetail.pk_status == 5) ? 'two1':'two')+'.png)'}">
                <img class="record_head" :src="twoUser.pic" alt=""  @click="checkUser(twoUser.id)">
                <div class="display_flex align-items_center margin-top-sm">
                    <div class="record_name">{{twoUser.nickname}}</div>
                    <div class="cha-level margin-left-xs" :style="{backgroundImage: 'url(' + 'img/level/level0/'+twoUser.level+'.png' + ')'}"></div>
                </div>
                <div class="record_id margin-top-xs">ID:{{twoUser.u_id}}</div>
                <div class="record_charm margin-top-xs">魅力值：{{twoUser.count_charm}}</div>
                <img v-if="pkDetail.pk_status == 1 || pkDetail.pk_status == 2" class="record_status" src="img/fail.png" alt="">
                <img v-if="pkDetail.pk_status == 3" class="record_status" src="img/ping.png" alt="">
            </div>
        </div>
        <div v-if="pkDetail.pk_status == 4 || pkDetail.pk_status == 5" class="record_over display_flex justify-content_flex-center align-items_center margin-top">
            <img v-if="pkDetail.pk_status == 5" src="img/guanli.png" alt="">
            <span class="margin-left-xs"><span v-if="pkDetail.pk_status == 4">{{pkDetail.end_user ==oneUser.id ? oneUser.nickname:twoUser.nickname}}</span>提前结束</span>
        </div>
        <div class="record_nav margin-top">贡献榜</div>
        <div class="padding-top-sm">

            <div class="pk_noData" v-if="charmList.length == 0">
              <img src="img/noData.png" alt="">
            </div>

            <div class="display_flex justify-content_flex-justify align-items_center padding-sm" v-for="(item,index) in charmList" :key="index">
                <div class="display_flex align-items_center">
                    <div class="cha-order">
                        <img v-if="index<3" class="cha-num" :src="'img/'+(index+1)+'.png'" alt="">
                        <span v-else>{{index+1}}</span>
                    </div>
                    <img class="cha-img margin-left" :src="item.pic ? item.pic:'img/nickPic.png'" alt=""  @click="checkUser(item.send_user_id)">
                    <div class="margin-left-sm">
                        <div class="display_flex align-items_center">
                            <div class="cha-name">{{item.nickname}}</div>
                            <div class="cha-level margin-left-xs" :style="{backgroundImage: 'url(' + 'img/level/level0/'+item.level+'.png' + ')'}"></div>
                        </div>
                        <div class="cha-id margin-top-xs">ID:{{item.u_id}}</div>
                    </div>
                </div>
                <div class="record_value display_flex flex-direction_column align-items_flex-end">
                    <img src="img/gongxian.png" alt="">
                    <span class="margin-top-xs">{{item.total_charm}}</span>
                </div>
            </div>

        </div>
    </div>

  </div>
</template>

<script>
// 移动端access_token，pk_id
import "@/assets/css/rank.css"
import "@/assets/css/pk.css"
import {pkDetails,pkDetailsList} from "@/api/pkCharm";

export default {
  name: 'recordDetail',
  data () {
    return {
      oneUser:{},
      twoUser:{},
      pkDetail:{},
      charmList:[]
    }
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    this.fetchData()
    this.geList()
  },
  methods: {
    fetchData() {
      const Params = {
        pk_id:this.$route.query.pk_id
      }
      pkDetails(Params).then(response => {
        this.pkDetail = response.data
        if(response.data.pk_status == 1 || response.data.pk_status == 2){
          if(response.data.win_user == response.data.user_a_info.id){
            this.oneUser = response.data.user_a_info
            this.twoUser = response.data.user_b_info
          }else{
            this.oneUser = response.data.user_b_info
            this.twoUser = response.data.user_a_info
          }
        }else{
          this.oneUser = response.data.user_a_info
          this.twoUser = response.data.user_b_info
        }
      },
      error => {
        this.$toast(error)
      }).catch((error) => {
        this.$toast(error)
      })
    },
    geList(){
      const Params = {
        pk_id:this.$route.query.pk_id
      }
      pkDetailsList(Params).then(response => {
        this.charmList = response.data
      },
      error => {
        this.$toast(error)
      }).catch((error) => {
        this.$toast(error)
      })
    },
    goback(){
      this.$router.go(-1);
    },
    checkUser(id){
      let param = id.toString()
      this.$bridge.callhandler('checkUser', param, (data) => {
      // 处理返回数据
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
